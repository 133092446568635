/* eslint-disable max-lines */
/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import {
  EntitlementType,
  IEntitlementTemplate,
  IGeography,
  MarketplaceFeatures,
} from '@vamstar/fox-api-common/esm/entitlement-template/types';
import React from 'react';
import { VamProduct } from '@vamstar/fox-api-common/esm/vam-product/types';
import { IReport } from '@vamstar/fox-api-common/esm/reports';
import { FilterKey } from '@vamstar/fox-api-common/esm/common/types';
import { getCountryNameByCode } from '@vamstar/fox-api-common/esm/utils';

import { MultiSelectField } from '../../../common/components/MultiSelectField';
import EntitlementTagModal from './EntitlementTagModal';
import { SingleSelectField } from '../../../common/components/SelectField';
import { NA } from '../../../constants';
import EntitlementPathModal from './EntitlementPathModal';
import { MultiEnumSelectField } from '../../../common/components/MultiEnumSelectField';
import { getReportsWithNameField } from '../utils';
import { ElasticDslEntitlementForm } from './ElasticDslEntitlementForm';
import { ElasticDslInformation } from './ElasticDslInformation';

interface EntitlementTemplateProps {
  products: VamProduct[];
  reports: IReport[];
  countries: IGeography[];
  marketplaceFeatures: MarketplaceFeatures[];
  entitlementtemplateData: Partial<IEntitlementTemplate>;
  setEntitlementType: (prop: string, value: any) => void;
  onChange: (prop: string, value: any) => void;
  allowEditAlways?: boolean;
}

class EntitlementTemplate extends React.Component<EntitlementTemplateProps> {
  isDisabled = () => {
    const {
      entitlementtemplateData: { _id },
      allowEditAlways,
    } = this.props;

    return !!_id && !allowEditAlways;
  };

  renderEntitlementTypeDropdown = () => {
    const {
      entitlementtemplateData: { type },
      setEntitlementType,
    } = this.props;

    return (
      <SingleSelectField
        data={[
          EntitlementType.PRODUCT,
          EntitlementType.GEOGRAPHY,
          EntitlementType.REPORT,
          EntitlementType.TAG,
          EntitlementType.PATH,
          EntitlementType.ELASTIC_DSL,
          EntitlementType.MARKETPLACE_FEATURES,
          EntitlementType.RELEVANCE_TAG,
        ]}
        label="Entitlement Type"
        onChange={(value) => setEntitlementType('type', value as string)}
        defaultValue={type}
        required
        hasError={false}
        disabled={this.isDisabled()}
      />
    );
  };

  renderPathDropDown = () => {
    const {
      entitlementtemplateData: { _id, type, paths },
      onChange,
      allowEditAlways,
    } = this.props;
    if (type === EntitlementType.PATH) {
      return (
        <EntitlementPathModal
          entitlementId={_id}
          entitledPath={
            paths || {
              allowedValues: [],
              excludedValues: [],
            }
          }
          onChange={(data) => onChange('paths', data)}
          allowEditAlways={allowEditAlways}
        />
      );
    }

    return null;
  };

  getModifiedReports = (selectedReports?: IReport[]) => {
    let modifiedReports: IReport[] = [];

    if (selectedReports && selectedReports.length) {
      modifiedReports = getReportsWithNameField(selectedReports);
    }

    return modifiedReports;
  };

  renderEntitlementForm = () => {
    const {
      products,
      reports,
      countries,
      entitlementtemplateData: { type, geography, reports: selectedReports, vamProducts },
      onChange,
    } = this.props;
    const modifiedReports = this.getModifiedReports(selectedReports);
    return (
      <>
        <FormControl variant="filled" fullWidth disabled={this.isDisabled()}>
          {type === EntitlementType.PRODUCT ? (
            <>
              <InputLabel>{type}</InputLabel>
              <MultiEnumSelectField
                data={products}
                value={vamProducts}
                onChange={(data) => {
                  onChange('vamProducts', data);
                }}
              />
            </>
          ) : null}
          {type === EntitlementType.GEOGRAPHY ? (
            <>
              <InputLabel>{type}</InputLabel>
              <MultiSelectField data={countries} value={geography} onChange={(data) => onChange('geography', data)} />
            </>
          ) : null}
          {type === EntitlementType.REPORT ? (
            <>
              <InputLabel>{type}</InputLabel>
              <MultiSelectField data={reports} value={modifiedReports} onChange={(data) => onChange('reports', data)} />
            </>
          ) : null}
          {this.renderMarketplaceFeatures()}
          {this.renderEntitlementFormComponents()}
        </FormControl>
      </>
    );
  };

  renderMarketplaceFeatures = () => {
    const {
      entitlementtemplateData: { type, marketplaceFeatures },
      marketplaceFeatures: allMarketplaceFeatures,
      onChange,
    } = this.props;
    return (
      <>
        {type === EntitlementType.MARKETPLACE_FEATURES ? (
          <>
            <InputLabel>{type}</InputLabel>
            <MultiEnumSelectField
              data={allMarketplaceFeatures}
              value={marketplaceFeatures}
              onChange={(data) => onChange('marketplaceFeatures', data)}
            />
          </>
        ) : null}
      </>
    );
  };

  renderRelevanceTagsForm = () => {
    const {
      entitlementtemplateData: { type, relevanceTags, _id },
      onChange,
      allowEditAlways,
    } = this.props;
    if (type) {
      return (
        <>
          {type === EntitlementType.RELEVANCE_TAG ? (
            <EntitlementTagModal
              entitlementId={_id}
              entitledTags={relevanceTags || []}
              onChange={(data) => onChange('relevanceTags', data)}
              allowEditAlways={allowEditAlways}
            />
          ) : null}
        </>
      );
    }
    return null;
  };

  renderEntitlementFormComponents = () => {
    const {
      entitlementtemplateData: { _id, type, tags, elasticDsl },
      onChange,
      allowEditAlways,
    } = this.props;
    if (type) {
      return (
        <>
          {type === EntitlementType.TAG ? (
            <EntitlementTagModal
              entitlementId={_id}
              entitledTags={tags || []}
              onChange={(data) => onChange('tags', data)}
              allowEditAlways={allowEditAlways}
            />
          ) : null}
          {this.renderRelevanceTagsForm()}
          {type === EntitlementType.ELASTIC_DSL ? (
            <ElasticDslEntitlementForm
              onAddingEntitlement={(dslEntitlement) => {
                onChange('elasticDsl', dslEntitlement);
              }}
              existingDslEntitlement={elasticDsl}
            />
          ) : null}
          {this.renderPathDropDown()}
        </>
      );
    }
    return <></>;
  };

  renderEntitlementType = () => {
    return (
      <>
        <Grid item xs={5}>
          {this.renderEntitlementTypeDropdown()}
        </Grid>
        <Grid item xs={5}>
          {this.renderEntitlementForm()}
        </Grid>
      </>
    );
  };

  renderProductInformation = () => {
    const {
      entitlementtemplateData: { vamProducts },
    } = this.props;
    return (
      <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
        {(vamProducts && vamProducts.join(', ')) || NA}
      </Typography>
    );
  };

  renderGeographyInformation = () => {
    const {
      entitlementtemplateData: { geography },
    } = this.props;
    return (
      <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
        {(geography &&
          geography
            .map((item) => {
              return item.name;
            })
            .join(', ')) ||
          NA}
      </Typography>
    );
  };

  renderReportInformation = () => {
    const {
      entitlementtemplateData: { reports },
    } = this.props;
    return (
      <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
        {(reports &&
          reports
            .map((item) => {
              return item.title;
            })
            .join(', ')) ||
          NA}
      </Typography>
    );
  };

  getCountryNames = (values: string[]) => {
    return values.map((code) => getCountryNameByCode(code));
  };

  getNewValuesForLocationFilter = (filterKey: FilterKey | undefined, values: string[]) => {
    if (filterKey && filterKey === 'entityIsoCountries') {
      return this.getCountryNames(values);
    }
    return values;
  };

  renderAllowedAndExcludedValues = (allowedValues: string[], excludedValues: string[], filterKey?: FilterKey) => {
    const newAllowedValues = this.getNewValuesForLocationFilter(filterKey, allowedValues);
    const newExcludedValues = this.getNewValuesForLocationFilter(filterKey, excludedValues);
    return (
      <>
        <Typography variant="body2">
          Allowed Values - {(newAllowedValues && newAllowedValues.join(', ')) || NA}
        </Typography>
        <Typography variant="body2">
          Excluded Values - {(newExcludedValues && newExcludedValues.join(', ')) || NA}
        </Typography>
      </>
    );
  };

  renderTagInformation = (type: EntitlementType) => {
    const {
      entitlementtemplateData: { tags = [], relevanceTags = [] },
    } = this.props;
    const newTags = type === EntitlementType.TAG ? [...(tags || [])] : [...(relevanceTags || [])];
    return (
      <Box>
        <Box>
          {newTags.map((tag, index) => (
            <Grid item key={tag.filterKey}>
              <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                {index + 1}. Field Name - {tag.filterKey}
              </Typography>
              {this.renderAllowedAndExcludedValues(tag.allowedValues, tag.excludedValues, tag.filterKey)}
            </Grid>
          )) || (
            <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
              {NA}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  renderPathInformation = () => {
    const {
      entitlementtemplateData: { paths },
    } = this.props;
    return (
      (paths && <Grid item>{this.renderAllowedAndExcludedValues(paths.allowedValues, paths.excludedValues)}</Grid>) || (
        <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
          {NA}
        </Typography>
      )
    );
  };

  // eslint-disable-next-line complexity
  renderSection = () => {
    const {
      entitlementtemplateData: { type, elasticDsl },
      onChange,
    } = this.props;

    let information = null;

    switch (type) {
      case EntitlementType.PRODUCT:
        information = this.renderProductInformation();
        break;
      case EntitlementType.GEOGRAPHY:
        information = this.renderGeographyInformation();
        break;
      case EntitlementType.REPORT:
        information = this.renderReportInformation();
        break;
      case EntitlementType.TAG:
      case EntitlementType.RELEVANCE_TAG:
        information = this.renderTagInformation(type);
        break;
      case EntitlementType.PATH:
        information = this.renderPathInformation();
        break;
      case EntitlementType.ELASTIC_DSL:
        information = (
          <ElasticDslInformation
            elasticDsl={elasticDsl}
            onRemoveDsl={() => {
              onChange('elasticDsl', undefined);
            }}
          />
        );
        break;
      default:
        break;
    }
    return information;
  };

  renderEntitlementTypeInformation = () => {
    const {
      entitlementtemplateData: { type },
    } = this.props;

    return (
      <Grid item>
        <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
          {type && type.toLowerCase()} Information
        </Typography>
        <Grid item style={{ marginTop: '5px' }} />
        {this.renderSection()}
      </Grid>
    );
  };

  render() {
    const {
      entitlementtemplateData: { name, status, description },
      onChange,
    } = this.props;

    return (
      <>
        <Grid item>
          <TextField
            disabled={this.isDisabled()}
            required
            variant="filled"
            fullWidth
            value={name || ''}
            error={!name || name === ''}
            label="Name"
            onChange={(e) => onChange('name', e.target.value as string)}
          />
        </Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          {this.renderEntitlementType()}
        </Grid>
        <Grid item>{this.renderEntitlementTypeInformation()}</Grid>
        <Grid item>
          <TextField
            disabled={this.isDisabled()}
            variant="filled"
            fullWidth
            value={description || ''}
            label="Description"
            onChange={(e) => onChange('description', e.target.value as string)}
          />
        </Grid>

        <Grid item>
          <FormControl variant="filled" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select label="Status" value={status} onChange={(e) => onChange('status', e.target.value as string)}>
              <MenuItem value="ACTIVE">ACTIVE</MenuItem>
              <MenuItem value="INACTIVE">INACTIVE</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  }
}

export default EntitlementTemplate;
