/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { ApolloClient } from '@apollo/client';
import { IEntitlementTemplate, MarketplaceFeatures } from '@vamstar/fox-api-common/esm/entitlement-template/types';
import { IReport, ReportStatus } from '@vamstar/fox-api-common/esm/reports';
import { VamProduct } from '@vamstar/fox-api-common/esm/vam-product/types';
import { ObjectId } from 'bson';
import { log } from '@vamstar/fox-node-logger';
import { countries as countryData } from '@vamstar/fox-api-common/esm/constants';

import { ENTITLEMENT_BY_ID, REPORTS } from './queries';

const take = 100;

interface ICountry {
  name: string;
  isoCode: string;
}
interface IData {
  entitlementtemplateData?: IEntitlementTemplate;
  products: VamProduct[];
  reports: IReport[];
  countries: ICountry[];
  marketplaceFeatures: MarketplaceFeatures[];
}

let data: IData = {
  entitlementtemplateData: undefined,
  products: [],
  reports: [],
  countries: [],
  marketplaceFeatures: [],
};

const getEntitlementById = async (client: ApolloClient<any>, id: string | ObjectId) => {
  const response = await client.query({
    query: ENTITLEMENT_BY_ID,
    variables: {
      id,
    },
  });

  data.entitlementtemplateData = response.data.entitlementtemplate;
};

export const getReportsWithNameField = (reports: IReport[]) => {
  return reports.map((e: IReport) => {
    return {
      ...e,
      name: e.title,
    };
  });
};

const fetchReports = async (client: ApolloClient<any>) => {
  try {
    const reportsResponse = await client.query({
      query: REPORTS,
      variables: {
        data: {
          skip: 0,
          take,
        },
      },
    });

    const { items } = reportsResponse.data.reports;
    const filteredReports = items.filter((e: IReport) => e.status !== ReportStatus.ARCHIVED);

    const modifiedReports = getReportsWithNameField(filteredReports);

    data.reports = modifiedReports;
  } catch (error) {
    log.error(JSON.stringify(error));
  }
};

const assignEntitlementInformation = () => {
  const { entitlementtemplateData } = data;
  if (entitlementtemplateData) {
    data.products = (entitlementtemplateData && entitlementtemplateData.vamProducts) || [];
    data.reports = entitlementtemplateData.reports || [];
  }
};
export const fetchData = async (client: ApolloClient<any>, _id?: string): Promise<IData> => {
  data = {
    products: [...Object.values(VamProduct)],
    marketplaceFeatures: [...Object.values(MarketplaceFeatures)],
    reports: [],
    countries: [],
    entitlementtemplateData: undefined,
  };
  if (_id) {
    await getEntitlementById(client, _id);
    assignEntitlementInformation();
  } else {
    await Promise.all([fetchReports(client)]);
  }

  const { countries } = data;
  countryData.forEach((country) => {
    countries.push({
      name: country.name,
      isoCode: country.code,
    });
  });

  return data;
};
