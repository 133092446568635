/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { Grid } from '@mui/material';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormTemplate, IApolloClient } from '@vamstar/fox-frontend-common';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { withSnackbar, ProviderContext } from 'notistack';
import { WithTranslation, withTranslation } from 'react-i18next';
import { LicenceType } from '@vamstar/fox-api-common/esm/licence/types';
import { EntitlementStatus, EntitlementType } from '@vamstar/fox-api-common/esm/entitlement-template/types';
import { log } from '@vamstar/fox-node-logger';

import {
  fetchData,
  fetchUsersData,
  onLicenceSave,
  isSaveDisabled,
  onLicenceUpdate,
  createEmEntitlement,
  getRights,
} from '../utils';
import EntitlementTemplateWrapperWithModal from '../../entitlement-template/EntitlementTemplateWithModal';
import EntitlementsTable from '../components/EntitlementsTable';
import {
  LicenceDateRenderer,
  LicenceTemplateRenderer,
  LicenceTypeRenderer,
  NameRenderer,
  NumberOfSeatsAndSeatsIncludedRenderer,
  OrganisationRenderer,
  StatusRenderer,
  TotalCostRenderer,
  UserRenderer,
} from '../components/renderers-one';
import { ILicenceState, licenceState, MatchParams } from '../constants';
import { MIN_SEARCH_STRING_LENGTH } from '../../../constants/pagination';
import { BasePriceAndPricePerSeatRenderer } from '../components/renderers-two';

type HOCProps = WithApolloClient<IApolloClient> & ProviderContext & WithTranslation & RouteComponentProps<MatchParams>;

class LicenceView extends React.Component<HOCProps, ILicenceState> {
  public state: ILicenceState = licenceState;

  async componentDidMount() {
    const {
      match: {
        params: { _type, _id },
      },
    } = this.props;

    const { client } = this.props;

    this.setState({ isLoading: true });

    const data = await fetchData(client, _type, _id);

    if (Object.keys(data.licenceData).length) {
      this.setState({
        licenceData: data.licenceData,
        selectedLicenceTemplateId: data.licenceData.licenceTemplate,
      });
    }

    this.setState({
      licenceTemplateData: data.licenceTemplateData,
      usersData: data.usersData,
      organisationsData: data.organisationsData,
      products: data.products,
      reports: data.reports,
      countries: data.countries,
      marketplaceFeatures: data.marketplaceFeatures,
      isLoading: false,
    });
  }

  setEntitlementType = (prop: string, value: any) => {
    const { entitlementtemplateData } = this.state;
    this.setState({
      entitlementtemplateData: {
        ...entitlementtemplateData,
        vamProducts: undefined,
        geography: undefined,
        reports: undefined,
        tags: undefined,
        elasticDsl: undefined,
        marketplaceFeatures: undefined,
        [prop]: value,
      },
    });
  };

  onEntitlementChange = (prop: string, value: any) => {
    const { entitlementtemplateData } = this.state;
    this.setState({
      entitlementtemplateData: {
        ...entitlementtemplateData,
        [prop]: value,
      },
    });
  };

  handleModal = () => {
    this.setState((prevState: ILicenceState) => ({ isModalVisible: !prevState.isModalVisible }));
  };

  onEntitlementTemplateSave = () => {
    const { entitlementtemplateData } = this.state;

    const emEntitlement = createEmEntitlement(entitlementtemplateData);
    this.setState((prevState: ILicenceState) => ({
      licenceData: {
        ...prevState.licenceData,
        rights: (prevState.licenceData.rights && [...prevState.licenceData.rights, emEntitlement]) || [emEntitlement],
      },
    }));
    this.handleModal();
  };

  onEntitlementTemplateUpdate = () => {
    const {
      licenceData: { rights },
      entitlementtemplateData,
      entitlementTemplateIndex,
    } = this.state;

    if (rights && entitlementTemplateIndex !== undefined) {
      const newRights = [...rights];
      const emEntitlement = createEmEntitlement(entitlementtemplateData);
      newRights.splice(entitlementTemplateIndex, 1, emEntitlement);

      this.setState((prevState: ILicenceState) => ({
        licenceData: {
          ...prevState.licenceData,
          rights: newRights,
        },
      }));
    }
    this.handleModal();
  };

  addNewEntitlementTemplate = () => {
    this.setState({
      entitlementtemplateData: {
        type: EntitlementType.PRODUCT,
        status: EntitlementStatus.ACTIVE,
      },
      entitlementTemplateIndex: undefined,
    });
    this.handleModal();
  };

  editEntitlementTemplate = (index: number) => {
    const {
      licenceData: { rights = [] },
    } = this.state;
    this.setState({
      entitlementtemplateData: rights[index] ?? undefined,
      entitlementTemplateIndex: index,
    });
    this.handleModal();
  };

  deleteEntitlementTemplate = (index: number) => {
    const {
      licenceData: { rights },
    } = this.state;
    if (rights) {
      const newRights = [...rights];
      newRights.splice(index, 1);
      this.setState((prevState: ILicenceState) => ({
        licenceData: {
          ...prevState.licenceData,
          rights: newRights,
        },
      }));
    }
  };

  onLicencePropsChange = (prop: string, value: any) => {
    this.setState((prevState: ILicenceState) => ({
      licenceData: {
        ...prevState.licenceData,
        [prop]: value,
      },
    }));
  };

  onSave = async () => {
    try {
      const response = await onLicenceSave(
        this.props.client,
        this.state.licenceData,
        this.state.selectedLicenceTemplateId,
      );

      const { _id } = response.data.createLicence;

      this.setState((prevState: ILicenceState) => ({
        licenceData: {
          ...prevState.licenceData,
          _id,
        },
      }));
      this.props.history.push({
        pathname: `/licence/${this.state.licenceData.type}/${_id}`,
      });

      this.props.enqueueSnackbar(this.props.t('recordCreated'), { variant: 'success' });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  onUpdate = async () => {
    try {
      await onLicenceUpdate(this.props.client, this.state.licenceData);

      this.props.enqueueSnackbar(this.props.t('recordUpdated'), { variant: 'success' });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  onLicenceTemplateChange = (event: any) => {
    this.setState((prevState: ILicenceState) => {
      const licenceTemplate = prevState.licenceTemplateData.find((element) => element._id === event.target.value);
      return {
        selectedLicenceTemplateId: event.target.value,
        licenceData: {
          ...prevState.licenceData,
          rights: getRights(prevState.licenceData.rights, licenceTemplate),
          basePrice: {
            currency: licenceTemplate && licenceTemplate.basePrice.currency,
            value: licenceTemplate && licenceTemplate.basePrice.value,
          },
          pricePerSeat: {
            currency: licenceTemplate && licenceTemplate.pricePerSeat.currency,
            value: licenceTemplate && licenceTemplate.pricePerSeat.value,
          },
        },
      };
    });
  };

  licenceTemplateRenderer = () => {
    return (
      <LicenceTemplateRenderer
        licenceData={this.state.licenceData}
        licenceTemplateData={this.state.licenceTemplateData}
        onLicenceTemplateChange={this.onLicenceTemplateChange}
        selectedLicenceTemplateId={this.state.selectedLicenceTemplateId}
      />
    );
  };

  renderEntitlementsTableAndModal = () => {
    return (
      <>
        <Grid item>
          <EntitlementsTable
            rights={this.state.licenceData.rights || []}
            editEntitlementTemplate={this.editEntitlementTemplate}
            deleteEntitlementTemplate={this.deleteEntitlementTemplate}
            addNewEntitlementTemplate={this.addNewEntitlementTemplate}
          />
        </Grid>
        <Grid item>
          <EntitlementTemplateWrapperWithModal
            products={this.state.products}
            reports={this.state.reports}
            countries={this.state.countries}
            marketplaceFeatures={this.state.marketplaceFeatures}
            entitlementtemplateData={this.state.entitlementtemplateData}
            entitlementTemplateIndex={this.state.entitlementTemplateIndex}
            onEntitlementTemplateSave={this.onEntitlementTemplateSave}
            onEntitlementTemplateUpdate={this.onEntitlementTemplateUpdate}
            setEntitlementType={this.setEntitlementType}
            onChange={this.onEntitlementChange}
            isModalVisible={this.state.isModalVisible}
            handleModal={this.handleModal}
          />
        </Grid>
      </>
    );
  };

  onLicenceTypeChange = (value: string) => {
    const { licenceData } = this.state;
    this.setState({
      licenceData: {
        ...licenceData,
        type: (value as unknown) as LicenceType,
        user: undefined,
        organisation: undefined,
      },
    });
  };

  onUserChange = async (value: string) => {
    try {
      if (value.length > MIN_SEARCH_STRING_LENGTH) {
        const { client } = this.props;
        const users = await fetchUsersData(client, value);
        if (users.length > 0) this.setState({ usersData: users });
      }
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  renderUser = () => {
    const { licenceData, usersData } = this.state;
    return (
      <UserRenderer
        licenceData={licenceData}
        usersData={usersData}
        onInputChange={this.onUserChange}
        onChange={this.onLicencePropsChange}
      />
    );
  };

  renderOrganisation = () => {
    return (
      <OrganisationRenderer
        licenceData={this.state.licenceData}
        organisationsData={this.state.organisationsData}
        onChange={this.onLicencePropsChange}
      />
    );
  };

  render() {
    const {
      isLoading,
      licenceData,
      licenceData: { _id, name, type, startDate, endDate },
    } = this.state;

    return (
      <FormTemplate
        isLoading={isLoading}
        onSubmit={() => (_id ? this.onUpdate() : this.onSave())}
        isEdit={!!_id}
        formLabel="Licence"
        isFormDisabled={isSaveDisabled(licenceData)}
      >
        <Grid container item direction="column" spacing={4}>
          <NameRenderer name={name} onChange={this.onLicencePropsChange} />
          {this.licenceTemplateRenderer()}
          {this.renderEntitlementsTableAndModal()}
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <LicenceTypeRenderer licenceData={licenceData} onChange={this.onLicenceTypeChange} />
            {type === LicenceType.USER ? this.renderUser() : this.renderOrganisation()}
          </Grid>
          <LicenceDateRenderer startDate={startDate} endDate={endDate} onChange={this.onLicencePropsChange} />
          <BasePriceAndPricePerSeatRenderer licenceData={licenceData} onChange={this.onLicencePropsChange} />
          <NumberOfSeatsAndSeatsIncludedRenderer licenceData={licenceData} onChange={this.onLicencePropsChange} />
          <Grid item container direction="row" alignItems="baseline">
            <TotalCostRenderer licenceData={licenceData} />
          </Grid>
          <StatusRenderer licenceData={licenceData} onChange={this.onLicencePropsChange} />
        </Grid>
      </FormTemplate>
    );
  }
}

const Licence: React.ComponentClass<{}, ILicenceState> = compose<HOCProps, {}>(
  withApollo,
  withRouter,
  withSnackbar,
  withTranslation('common'),
)(LicenceView);

export default Licence;
