import { IEntitlementTemplate, MarketplaceFeatures } from '@vamstar/fox-api-common/esm/entitlement-template/types';
import { ILicenceTemplate } from '@vamstar/fox-api-common/esm/licence-template/types';
import { ILicence, LicenceStatus, LicenceType } from '@vamstar/fox-api-common/esm/licence/types';
import { IOrganisation } from '@vamstar/fox-api-common/esm/organisation/types';
import { IReport } from '@vamstar/fox-api-common/esm/reports';
import { IEmUser } from '@vamstar/fox-api-common/esm/user/types';
import { VamProduct } from '@vamstar/fox-api-common/esm/vam-product/types';

import { CURRENCY } from '../../constants';

export interface MatchParams {
  _id: string;
  _type: LicenceType;
}

export interface ICountry {
  name: string;
  isoCode: string;
}

export interface ILicenceState {
  isLoading: boolean;
  licenceData: Partial<ILicence>;
  licenceTemplateData: ILicenceTemplate[];
  selectedLicenceTemplateId?: string;
  products: VamProduct[];
  reports: IReport[];
  countries: ICountry[];
  entitlementtemplateData: Partial<IEntitlementTemplate>;
  isModalVisible: boolean;
  entitlementTemplateIndex?: number;
  usersData: IEmUser[];
  organisationsData: IOrganisation[];
  elasticDsl: string;
  marketplaceFeatures: MarketplaceFeatures[];
}

export const licenceState: ILicenceState = {
  isLoading: false,
  licenceData: {
    basePrice: {
      currency: CURRENCY.GBP,
      value: undefined,
    },
    startDate: new Date(),
    // eslint-disable-next-line no-magic-numbers
    endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    pricePerSeat: {
      currency: CURRENCY.GBP,
      value: undefined,
    },
    seatsIncluded: 1,
    numberOfSeats: 1,
    status: LicenceStatus.ACTIVE,
    type: LicenceType.USER,
  },
  licenceTemplateData: [],
  products: [],
  reports: [],
  countries: [],
  entitlementtemplateData: {},
  elasticDsl: '',
  isModalVisible: false,
  entitlementTemplateIndex: undefined,
  usersData: [],
  organisationsData: [],
  selectedLicenceTemplateId: undefined,
  marketplaceFeatures: [],
};
