/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { Grid } from '@mui/material';
import {
  EntitlementStatus,
  EntitlementType,
  IEntitlementTemplate,
  MarketplaceFeatures,
} from '@vamstar/fox-api-common/esm/entitlement-template/types';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormTemplate, IApolloClient } from '@vamstar/fox-frontend-common';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { VamProduct } from '@vamstar/fox-api-common/esm/vam-product/types';
import { IReport } from '@vamstar/fox-api-common/esm/reports';
import { withSnackbar, ProviderContext } from 'notistack';
import { WithTranslation, withTranslation } from 'react-i18next';
import { log } from '@vamstar/fox-node-logger';
import loOmit from 'lodash/omit';

import EntitlementTemplate from '../component/EntitlementTemplate';
import { fetchData } from '../utils';
import { CREATE_ENTITLEMENT, UPDATE_ENTITLEMENT } from '../mutations';
import { deepOmitPropArray } from '../../../util/object';
import { isSuperAdmin } from '../../../common/utils';

type HOCProps = WithApolloClient<IApolloClient> & ProviderContext & WithTranslation & RouteComponentProps<MatchParams>;

interface MatchParams {
  _id: string;
}

interface ICountry {
  name: string;
  isoCode: string;
}

interface IEntitlementTemplateState {
  entitlementtemplateData: Partial<IEntitlementTemplate>;
  products: VamProduct[];
  reports: IReport[];
  isLoading: boolean;
  countries: ICountry[];
  marketplaceFeatures: MarketplaceFeatures[];
}

class EntitlementTemplateFormView extends React.Component<HOCProps, IEntitlementTemplateState> {
  state: IEntitlementTemplateState = {
    entitlementtemplateData: {
      status: EntitlementStatus.ACTIVE,
      type: EntitlementType.PRODUCT,
      geography: [],
      reports: [],
      tags: [],
      vamProducts: [],
      elasticDsl: '',
      marketplaceFeatures: [],
    },
    products: [],
    reports: [],
    countries: [],
    marketplaceFeatures: [],
    isLoading: false,
  };

  async componentDidMount() {
    const {
      client,
      match: {
        params: { _id },
      },
    } = this.props;
    this.setState({ isLoading: true });

    const { entitlementtemplateData, products, reports, countries, marketplaceFeatures } = await fetchData(client, _id);

    if (entitlementtemplateData) {
      this.setState({ entitlementtemplateData });
    }
    this.setState({ products, reports, countries, marketplaceFeatures, isLoading: false });
  }

  onSave = async (): Promise<void> => {
    const { entitlementtemplateData } = this.state;
    const { client, t, enqueueSnackbar } = this.props;
    const { name, status, type, description, geography, reports, tags, vamProducts, paths } = entitlementtemplateData;
    const { relevanceTags } = entitlementtemplateData;
    const { marketplaceFeatures } = entitlementtemplateData;
    const modifiedReports = deepOmitPropArray(reports, '__typename');
    const newReports = deepOmitPropArray(modifiedReports, 'name');
    const newEntitlementTags = deepOmitPropArray(tags, '__typename');
    const newRelevanceTags = deepOmitPropArray(relevanceTags, '__typename');
    let newEntitlementPath;

    if (type === EntitlementType.PATH) {
      newEntitlementPath = loOmit(paths, ['__typename']);
    }
    try {
      const response = await client.mutate({
        mutation: CREATE_ENTITLEMENT,
        variables: {
          data: {
            type,
            name,
            description,
            vamProducts,
            geography,
            reports: newReports,
            tags: newEntitlementTags,
            relevanceTags: newRelevanceTags,
            paths: newEntitlementPath,
            marketplaceFeatures,
            status,
          },
        },
      });
      const { _id } = response.data.createEntitlementtemplate;
      this.setState({
        entitlementtemplateData: {
          ...entitlementtemplateData,
          _id,
        },
      });
      this.props.history.push({
        pathname: `/entitlement-template/${_id}`,
      });
      enqueueSnackbar(t('recordCreated'), { variant: 'success' });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  onUpdate = async () => {
    const {
      entitlementtemplateData: { status },
    } = this.state;
    const { _id } = this.props.match.params;
    const { client, t, enqueueSnackbar } = this.props;
    try {
      await client.mutate({
        mutation: UPDATE_ENTITLEMENT,
        variables: {
          data: {
            filter: { id: _id },
            doc: {
              status,
            },
          },
        },
      });
      enqueueSnackbar(t('recordUpdated'), { variant: 'success' });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  setEntitlementType = (prop: string, value: any) => {
    const { entitlementtemplateData } = this.state;

    this.setState({
      entitlementtemplateData: {
        ...entitlementtemplateData,
        vamProducts: undefined,
        geography: undefined,
        reports: undefined,
        tags: undefined,
        relevanceTags: undefined,
        paths: undefined,
        elasticDsl: undefined,
        marketplaceFeatures: undefined,
        [prop]: value,
      },
    });
  };

  onChange = (prop: string, value: any) => {
    const { entitlementtemplateData } = this.state;
    this.setState({
      entitlementtemplateData: {
        ...entitlementtemplateData,
        [prop]: value,
      },
    });
  };

  isSaveDisabled = (): boolean => {
    const {
      entitlementtemplateData: { name },
    } = this.state;

    return !name || name === '';
  };

  render() {
    const { isLoading, entitlementtemplateData, products, reports, countries, marketplaceFeatures } = this.state;

    return (
      <FormTemplate
        isLoading={isLoading}
        onSubmit={() => (entitlementtemplateData._id ? this.onUpdate() : this.onSave())}
        isEdit={!!entitlementtemplateData._id}
        formLabel="Entitlement Template"
        isFormDisabled={isSuperAdmin() ? this.isSaveDisabled() : true}
      >
        <Grid container item direction="column" spacing={4}>
          <EntitlementTemplate
            entitlementtemplateData={entitlementtemplateData}
            products={products}
            reports={reports}
            countries={countries}
            marketplaceFeatures={marketplaceFeatures}
            setEntitlementType={this.setEntitlementType}
            onChange={this.onChange}
          />
        </Grid>
      </FormTemplate>
    );
  }
}

const EntitlementTemplateForm: React.ComponentClass<{}, IEntitlementTemplateState> = compose<HOCProps, {}>(
  withApollo,
  withRouter,
  withSnackbar,
  withTranslation('common'),
)(EntitlementTemplateFormView);

export default EntitlementTemplateForm;
